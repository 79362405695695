<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 10:39:55
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-02-22 15:23:57
 * @Description: 
-->
<!-- 种苗页面 -->
<template>
  <div class="enterpriseInfo">
    <div>
      <tableTemplate
        class="table"
        :searchSelectList="industryList"
        @searchFormType="searchFormType"
        :searchListInfo="searchListInfo"
        @searchBut="searchBut"
        addName="添加记录"
        :loading="loading"
        :data="tableData"
        :columns="columns"
        @addClick="addClick"
        :seceltModel="seceltModel"
        :timeInterval="timeInterval"
        @sectionChange="sectionChange"
      >
        <template slot="avatar" slot-scope="scope">
          <div slot="avatar" class="operation">
            <span @click="infoOpen(scope.data.row)">详情</span>
            <span @click="eait(scope.data.row)">编辑</span>
            <span @click="delect(scope.data.row)">删除</span>
          </div>
        </template>
      </tableTemplate>
      <page
        @changePage="eventPage"
        @changeSize="eventPage"
        class="pageStyle"
        :pagination="pagination"
      />
      <!-- 添加 -->
      <el-dialog :title="titles" :visible.sync="dialogVisible" width="30%">
        <el-form
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="120px"
        >
          <el-form-item label="种苗名称" prop="name">
            <el-input :disabled="batchInfo" v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="购买数量" prop="purchaseQuantity">
            <el-input
              :disabled="batchInfo"
              v-model="addForm.purchaseQuantity"
            ></el-input>
          </el-form-item>
          <el-form-item label="购买数量单位" prop="purchaseQuantityUnit">
            <el-input
              :disabled="batchInfo"
              v-model="addForm.purchaseQuantityUnit"
            ></el-input>
          </el-form-item>
          <el-form-item label="规格" prop="specifications">
            <el-input
              :disabled="batchInfo"
              v-model="addForm.specifications"
            ></el-input>
          </el-form-item>
          <el-form-item label="采购人" prop="purchaserId">
            <el-select
              :disabled="batchInfo"
              v-model="addForm.purchaserId"
              placeholder="请选择采购人"
              @change="purchaserName"
            >
              <el-option
                v-for="item in industryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="采购时间" prop="procurementTime">
            <el-date-picker
              :disabled="batchInfo"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="addForm.procurementTime"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="经销商" prop="dealer">
            <el-input :disabled="batchInfo" v-model="addForm.dealer"></el-input>
          </el-form-item>
          <el-form-item label="经销商负责人" prop="dealerPrincipal">
            <el-input
              :disabled="batchInfo"
              v-model="addForm.dealerPrincipal"
            ></el-input>
          </el-form-item>
          <el-form-item label="经销商电话" prop="dealerPrincipalTel">
            <el-input
              :disabled="batchInfo"
              v-model="addForm.dealerPrincipalTel"
            ></el-input>
          </el-form-item>
          <el-form-item label="生产商" prop="manufacturer">
            <el-input
              :disabled="batchInfo"
              v-model="addForm.manufacturer"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="备注" prop="remarks">
            <el-input :disabled="batchInfo" v-model="addForm.remarks"></el-input>
          </el-form-item> -->种苗照片" v-if="!batchInfo">
            <el-upload
              class="upload-demo"
              action="/imgUpdata"
              :limit="1"
              :file-list="fileList"
              :on-change="handleChange"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed"
              :on-success="handleAvatarSuccess"
              :on-remove="onRemove"
            >
              <div class="upFlieBox">
                <i class="el-icon-upload"></i><span>点击上传</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="质量监测报告" v-if="!batchInfo">
            <el-upload
              class="upload-demo"
              action="/imgUpdata"
              :limit="2"
              :file-list="fileList2"
              :on-change="handleChange2"
              :before-upload="beforeAvatarUpload2"
              :on-exceed="handleExceed2"
              :on-success="handleAvatarSuccess2"
              :on-remove="onRemove2"
            >
              <div class="upFlieBox">
                <i class="el-icon-upload"></i><span>点击上传</span>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" v-if="!batchInfo">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button
            class="butColor"
            size="small"
            type="primary"
            @click="saveFrom(addForm, 'addForm')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import tableTemplate from "@/components/soureUnit//tableTemplate.vue";
import page from "@/components/page.vue";
import { newRecord } from "@/utils/required.js";
export default {
  components: {
    tableTemplate,
    page,
  },
  data() {
    return {
      columns: [
        { label: "种苗名称", prop: "name", align: "center" },
        { label: "购买数量", prop: "purchaseQuantity", align: "center" },
        { label: "购买数量单位", prop: "purchaseQuantityUnit", align: "center" },
        { label: "规格", prop: "specifications", align: "center" },
        { label: "采购人", prop: "purchaser", align: "center" },
        { label: "采购时间", prop: "procurementTime", align: "center" },
        { label: "经销商", prop: "dealer", align: "left" },
        { label: "经销商负责人", prop: "dealerPrincipal", align: "left" },
        { label: "经销商电话", prop: "dealerPrincipalTel", align: "left" },
        // { label: '生产商', prop: 'manufacturer', align: 'left' },
        {
          label: "操作",
          prop: "avatar",
          align: "center",
          __slotName: "avatar",
        },
      ],
      tableData: [],
      addForm: {},
      dialogVisible: false,
      titles: "新建",
      pagination: {
        totalRow: 0,
        page: 1,
        size: 8,
      },
      fileList: [],
      fileList2: [],
      seceltModel: true,
      rules: newRecord,
      enterpriseList: [
        //企业信息
        { name: "有限公司", value: 0 },
        { name: "无线公司", value: 1 },
      ],
      industryList: [],
      loading: true,
      searchListInfo: {
        name: "种苗名称",
        type: "采购人",
        timeInterval: "选择时间",
      },
      timeInterval: true,
      batchInfo: false,
    };
  },
  mounted() {
    this.requestLsit();
    this.staffList(); //员工
  },
  methods: {
    staffList() {
      this.$get("/enterpriseManage/querryStaff", { page: 1, size: 10000 }).then(
        (res) => {
          if (res.data.state == "success") {
            let { datas } = res.data;
            this.industryList = datas;
          }
        }
      );
    },
    purchaserName(val) {
      this.addForm.purchaser = this.industryList.find((v) => v.id == val).name;
    },
    addClick() {
      this.dialogVisible = true;
      this.titles = "新建";
      this.batchInfo = false;
      if (this.$refs["addForm"] !== undefined) {
        this.$nextTick(() => {
          this.$refs["addForm"].resetFields();
          this.addForm = {};
          this.fileList = [];
          this.fileList2 = [];
        });
      }
    },
    exitLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    requestLsit(name, startTime, endTime, purchaser) {
      // 企业信息
      this.$get("/input/getInputPurchaseRecordList", {
        page: this.pagination.page,
        size: this.pagination.size,
        type: 0,
        name: name || "",
        startTime: startTime || "",
        endTime: endTime || "",
        purchaser: purchaser || "",
      })
        .then((res) => {
          if (res.data.state == "success") {
            let { datas } = res.data;
            this.pagination.totalRow = res.data.count;
            this.tableData = datas;
            this.exitLoading();
          }
        })
        .catch((err) => {
          this.exitLoading();
          throw err;
        });
    },
    // 保存
    saveFrom(data, dom) {
      let url = "/input/addInputPurchaseRecord";
      let inputsImg = []; //投入品
      let reportImg = []; //质量监测报告
      let demoUrl = "https://public.half-half.cn/";

      this.$refs[dom].validate((valid) => {
        if (valid) {
          if (this.fileList.length == 0)
            return this.$message.info("请上传种苗照片");
          if (this.fileList2.length == 0)
            return this.$message.info("请上传质量监测报告");
          //上传图片格式处理
          if (this.titles == "新建") {
            delete data.id;
            this.fileList.forEach((v) =>
              inputsImg.push({
                name: v.name,
                response: v.response,
                url: demoUrl + v.response.data.fileName,
              })
            );
            this.fileList2.forEach((p) =>
              reportImg.push({
                name: p.name,
                response: p.response,
                url: demoUrl + p.response.data.fileName,
              })
            );
          } else if (this.titles == "修改") {
            this.fileList.forEach((v) =>
              inputsImg.push({
                name: v.name,
                response: v.response,
                url: demoUrl + v.response.data.fileName,
              })
            );
            this.fileList2.forEach((p) =>
              reportImg.push({
                name: p.name,
                response: p.response,
                url: demoUrl + p.response.data.fileName,
              })
            );
          }
          data.inputsImg = JSON.stringify(inputsImg);
          data.reportImg = JSON.stringify(reportImg);
          data.type = 0;

          this.$post(url, data).then((res) => {
            // //console.log(res, '接口数据');
            if (res.data.state == "success") {
              this.dialogVisible = false;
              this.$message.success(res.data.msg);
              this.requestLsit();
            }
          });
        }
      });
    },
    // 修改
    eait(data) {
      this.titles = "修改";
      this.batchInfo = false;
      this.dialogVisible = true;
      this.addForm = JSON.parse(JSON.stringify(data));
      if (data.inputsImg) {
        this.fileList = JSON.parse(data.inputsImg);
      } else {
        this.fileList = [];
      }
      if (data.reportImg) {
        this.fileList2 = JSON.parse(data.reportImg);
      } else {
        this.fileList2 = [];
      }
    },
    // 详情
    infoOpen(data) {
      this.titles = "详情";
      this.batchInfo = true;
      this.dialogVisible = true;
      this.$nextTick(() => (this.addForm = JSON.parse(JSON.stringify(data))));
    },
    //删除
    delect(data) {
      this.$confirm(`此操作将永久删除 ${data.name} 信息, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/input/delInputPurchaseRecord", { id: data.id }).then(
            (res) => {
              if (res.data.state == "success") {
                this.requestLsit();
                this.loading = true;
                this.$message.success(res.data.msg);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //
    searchBut(data) {
      this.pagination.page = 1;
      this.pagination.size = 8;
      this.requestLsit(data.name);
    },
    sectionChange(sectionChange, name, userName) {
      this.requestLsit(
        name || "",
        sectionChange[0],
        sectionChange[1],
        userName || ""
      );
    },
    searchFormType(val, name, type) {
      if (!type) {
        type = [];
      }
      this.requestLsit(name || "", type[0] || "", type[1] || "", val);
    },
    eventPage() {
      this.requestLsit();
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      if (isJPG || isPng) {
        // this.uploadState = 0;
        return true;
      }
      this.$message.info("请检查上传格式！");
      this.fileList = [];
      return false;
    },
    handleExceed() {
      this.$message.info("只能上传一个文件");
    },
    handleAvatarSuccess(res, file) {
      this.addForm.inputsImg =
        "https://public.half-half.cn/" + res.data.fileName;
      //   //console.log(this.fileList);
    },
    onRemove(file, fileList) {
      this.addFormModel.inputsImg = "";
    },

    handleChange2(file, fileList) {
      this.fileList2 = fileList.slice(-2);
    },
    beforeAvatarUpload2(file) {
      let { name } = file;
      name = name.split(".")[1];
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      //   if (name == 'jpg' || name == 'png') {
      if (isJPG || isPng) {
        return true;
      }
      this.$message.info("请检查上传格式！");
      this.fileList = [];
      return false;
    },
    handleExceed2() {
      this.$message.info("只能上传两个文件");
    },
    handleAvatarSuccess2(res, file) {
      //   this.addForm.honorImg = 'https://public.half-half.cn/' + res.data.fileName;
    },
    onRemove2(file, fileList) {
      this.addFormModel.url = "";
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/style/colorPublic";
.butColor {
  // background: @but_color;
  background: #409eff;
}
.enterpriseInfo {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}
.operation {
  display: flex;
  justify-content: space-around;
  span {
    cursor: pointer;
  }
}
.enterpriseInfo /deep/ .el-dialog__title {
  font-size: 1.5vh;
}
.table {
  height: 82vh;
  overflow-y: auto;
}
</style>
